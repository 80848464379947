.discount_wrap {
  max-width: 440px;
  .section_title {
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 1.2rem;
  }
  input {
    border: none;
    border: 2px solid #8798aeb3;
    outline: none;
    padding: 8px;
    border-radius: 2px;
  }
  .discount_types_wrap {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    justify-content: center;
    align-items: stretch;
    .discount_type {
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
      cursor: pointer;
      &.disable {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
      &.active {
        background-color: #008700;
        color: white;
        border-color: transparent;
      }
      padding: 8px 6px;
      border: 2px solid #8798aeb3;
      &:first-of-type {
        border-radius: 2px 0 0 2px;
        border-right: none;
      }
      &:nth-of-type(2) {
        border-right: none;
      }
      &:last-of-type {
        border-radius: 0 2px 2px 0;
      }
    }
  }
}

.label {
  width: 100%;
  max-width: none;
  margin-bottom: 0 !important;
}
