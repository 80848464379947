.wrap {
  padding: 0;
  position: relative;
  max-width: 440px;
  width: 100%;
}

.error_text {
  font-size: 12px;
  color: #ff4343;
  font-weight: 500;
}
.info_text {
  font-size: 12px;
  color: #8798ae;
  font-weight: 500;
  line-height: 1.6;
  display: inline-block;
}

label.wrap input.form_field {
  outline: none;
  border: none;
  border: 2px solid #8798aeb3;
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  width: 100%;
  border-radius: 2px;

  &:disabled,
  &:read-only {
    opacity: 0.6;
  }
  &.error {
    border: 2px solid #ff4343;
  }
}

.field_name {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2px;
  padding-bottom: 3px;
  display: block;
}
