.plans_wrap {
  max-width: 440px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  .plan_card {
    cursor: pointer;
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: flex-start;
    background-color: #f6f8fa;
    border-radius: 1rem;
    padding: 1rem;
    gap: 10px;
    .radio {
      margin-top: 2px;
      svg {
        color: #008700;
        font-size: 20px;
      }
      &.error svg {
        color: #ff4343;
      }
    }
    .plan_data {
      gap: 1rem;
      display: grid;
      grid-template-columns: auto max-content;
      .plan_texts {
        .plan_title {
          font-size: 18px;
          font-weight: 600;
          color: #212529;
        }
        .plan_description {
          font-size: 12px;
          color: #008700;
        }
      }
      .plan_amount_wrap {
        text-align: end;
        .plan_amount {
          font-size: 24px;
          color: #008700;
          font-weight: 600;
        }
        .plan_type {
          font-size: 12px;
          color: #1c1c1c;
        }
      }
    }
    &.disabled {
      pointer-events: none;
      cursor: none;
    }
  }
}
