body {
  background-color: #e0e3e8;
  font-family: "Poppins", sans-serif;
}

/* 
primary-color: 4542e1

*/

.pointer {
  cursor: pointer !important;
}

/* ---Sidebar css */
.text-primary {
  color: #008700 !important;
}
.btn-primary {
  border-color: #008700 !important;
  background-color: #008700 !important;
  color: #fff;
}

/* .header-top-section {
  background-color: #2f3c4e;
  color: white;
} */

.header-section {
  left: 280px;
  width: calc(100% - 280px) !important;
  position: fixed;
  overflow: auto;
}

.header-section.active {
  left: 0px;
  position: fixed;
  width: 100% !important;
}

.header-toggle {
  padding: 10px;
}

.menu-icon {
  font-size: 24px;
  color: #008700;
}

.form-inline .navbar-brand {
  align-items: center;
  display: flex;
}

.sidebar {
  width: 280px;
  min-height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 1;
  transition: 0.5s;
}

.sidebar.active {
  left: 0;
}

.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  color: white;
}

.sd-header .sidebar-logo {
  width: 190px;
  margin: auto;
  padding-top: 1rem;
}

/* .sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
} */
.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}

.sd-body {
  padding: 15px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}

.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.sd-body ul li {
  list-style: none;
  margin-bottom: 8px;
}

.sd-body ul .sd-link {
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  color: #000000;
  opacity: 0.8;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.sd-link:hover {
  background-color: #3ab141;
  color: #fff !important;
  /* 297C34 */
}

.sd-link.active {
  color: #fff !important;
  background-color: #3ab141;
}

.body-content-section {
  margin: 10px;
  height: calc(100vh - 100px);
  /* background-color: white; */
  border-radius: 10px;
  padding: 10px;
}

.breadcrumb-section {
  font-size: 14px;
  color: #515151;
  display: flex;
}

.breadcrumb-section p {
  margin-right: 10px;
}

.breadcrumb-section p::after {
  margin-left: 10px;
  content: "/";
}

.breadcrumb-section p.active {
  color: #008700;
}

.breadcrumb-section p.active::after {
  content: "";
}

.breadcrumb-section.onhover:hover {
  color: #008700;
}

.card {
  padding: 10px;
  border: 0;
  border-radius: 10px;
}

.card-head {
  padding: 0 15px;
  color: #555;
  font-size: 14px;
}

.activity_logs .activity_logs_card_header {
  font-weight: 600;
  padding: 0 15px;
  margin-bottom: 10px;
}

.sortable .order {
  display: contents;
}

.font-small-size {
  font-size: 12px;
}

.sortable .react-bootstrap-table-sort-order {
  display: contents !important;
}

@media (max-width: 991px) {
  .activity_logs_card_header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
  }

  .activity_logs_card_header .header-section-tile {
    justify-content: center;
    align-items: flex-start !important;
  }

  .activity_logs_card_header .header-section-tile .search-wrap {
    align-items: center !important;
    min-width: 100% !important;
    width: 100% !important;
  }
}

.height-500 {
  height: 500px;
}

/*  table */

.main-table {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.main-table table {
  margin-bottom: 0;
}

.main-table .table thead tr {
  background-color: #eff2f7;
}

Table tr:nth-child(odd) {
  background: rgb(250, 251, 253);
}

tr:nth-child(even) {
  background-color: #f8f8f8;
}
.table > tbody > tr > td,
.table-striped > tbody > tr > th {
  border: transparent !important;
}

.table > thead {
  border-radius: 10px !important;
}

.table > thead > tr > th {
  border: transparent !important;
}

table {
  margin-bottom: 5px !important;
}

.table th {
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

table td,
table th {
  vertical-align: middle;
}

table td {
  color: #7a7a7a;
  font-size: 14px;
}

.table > tbody > tr:active {
  color: #000 !important;
}

.react-bs-table-sizePerPage-dropdown #pageDropDown {
  color: #000000 !important;
  background-color: #d3d7e7;
  border-color: #d3d7e7;
}

.pagination .page-item .page-link {
  border-radius: 12px !important;
  background-color: #000000;
  color: #fff;
}

.pagination .page-item.active .page-link {
  background-color: #000000;
  opacity: 0.7;
}

table,
th,
td {
  border: 1px solid #eff2f7;
  border-collapse: collapse;
}

.table-nowrap > tbody > tr > .grow-btn {
  padding: 9px 32px;
  border-radius: 38px !important;
}

.table-responsive {
  border-radius: 8px;
}

.scrollable-table {
  max-height: 280px;
}

tbody tr:hover {
  background: rgb(255, 255, 255) !important;
  box-shadow: 0px 0px 60px rgba(42, 48, 66, 0.06);
}

tbody tr:hover td {
  color: #000;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: #fff !important;
  color: unset;
}
thead > tr {
  border-radius: 12px !important;
}

thead > tr > th {
  background-color: #2f3c4e !important;
  color: white !important;
}

.sort-arrows {
  float: right;
}
.sort-arrows img {
  height: 15px;
  width: 7px;
}

.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #212121;
}

.react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #212121;
}

.react-bootstrap-table th .order,
.react-bootstrap-table th .react-bootstrap-table-sort-order {
  float: right;
}

.pagination .btn-next {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 1px solid #000000;
  background: #000000;
  border-radius: 11px;
  color: #fff;
  margin-left: 5px;
}
.btn-next:disabled {
  opacity: 0.7;
}

.pagination .btn-prev {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 1px solid #000000;
  background: #000000;
  border-radius: 11px;
  color: #fff;
}
.btn-prev:disabled {
  opacity: 0.7;
}

.bootstrap-header-classes {
  background-color: #008700 !important;
}

.bootstrap-header-classes th > span {
  margin-right: 20px;
}

.filter-label :where(span, input) {
  font-size: 12px;
  /* width: 150px; */
  /* margin-left: 20px; */
}

.react-bootstrap-table {
  padding: 0 15px;
}

.btn-link:hover {
  background-color: transparent;
}
.logout {
  color: red;
  text-decoration: underline;
}
.logout:hover {
  color: #008700 !important;
}

/* Pagination design */

.react-bootstrap-table-pagination {
  padding: 0 15px;
  font-size: 12px;
}

.pagination {
  gap: 5px !important;
}

.pagination .page-item.active .page-link {
  background-color: #008700;
  border-color: #008700;
  opacity: 1;
}

.text-success {
  color: #008700 !important;
}

.btn-success {
  background-color: #008700 !important;
  border-color: #008700;
}
.btn-paid-resource {
  background-color: #e97e2d !important;
  border-color: #e97e2d;
}
.pagination .page-item .page-link {
  background-color: #2f3c4e;
  border-color: #2f3c4e;
  font-size: 12px;
  border-radius: 1px !important;
}

.react-bs-table-sizePerPage-dropdown #pageDropDown {
  font-size: 12px;
  border-radius: 1px !important;
}

.react-bootstrap-table-pagination-total {
  margin-left: 8px;
}

.react-bootstrap-table-pagination-list {
  justify-content: flex-end;
  display: flex;
}

.dropdown-menu {
  font-size: 12px;
  /* top: 60px; */
}
.dropdown-menu.show {
  bottom: 25px;
}

.dropdown-menu .dropdown-item a {
  color: #2f3c4e !important;
  text-decoration: none;
  width: 100%;
  display: flex;
}

#pageDropDown .caret {
  border-bottom: none;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  color: white !important;
  /* background-color: white !important; */
  vertical-align: middle;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  /* transform: rotate(180deg) !important; */
}

.view-all-caret .caret,
.dropdown .dropup {
  display: none;
}

.paid-for-header-tab {
  /* width: 60px !important; */
  /* width:  200px !important; */
  /* display: flex;
  flex-direction: column; */
  /* width: 100%; */
}

.filter.select-filter.form-control {
  font-size: 12px;
  color: #636668;
  width: 100%;
}

.view-all-caret {
  width: 60px !important;
  text-align: center;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-bottom: 0;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  content: "";
}

.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 0px -8px 10px 5px !important;
}
.react-bootstrap-table th .order,
.react-bootstrap-table th .react-bootstrap-table-sort-order {
  float: none;
  margin-left: 5px;
}
.payment_types_tabs span {
  font-size: 10px;
  padding: 2px 5px;
  color: white;
  cursor: default;
}

.main_menu {
  cursor: pointer;
}

.main_menu:hover {
  color: #008700 !important;
}

.user_menu {
  cursor: pointer;
}

.user_menu::after {
  margin-left: 10px;
  content: "/";
}

.user_menu:hover {
  color: #008700 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.coupon_card {
  background-color: #f6f8fa;
  width: 100%;
  max-width: 440px;
}

.coupon_card .top_section {
  cursor: pointer;
}
.coupon_card .top_section .code {
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
}
.coupon_card .description {
  margin: 0;
  margin-top: 4px;
}

.text-xs {
  font-size: 13px;
}

.search-label input:focus {
  box-shadow: none;
  border-color: var(--bs-border-color);
  outline: none;
}
.search-label input {
  padding-right: 34px;
}

.search-clear {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 10px;
}
