.loader_wrap {
  width: 100%;
  justify-content: center;
  display: inline-flex;
  height: 100%;
  align-items: center;
  z-index: 999;
}

.loader {
  width: 4em;
  height: 4em;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: "";
  width: 4em;
  height: 4em;
  border-radius: 50%;
  border: 8px solid #008700;
  position: absolute;
  left: 0;
  top: 0;
  animation: loaderKeyframes 2s linear infinite;
  animation-fill-mode: both;
}
.loader::after {
  animation-delay: 1s;
}

@keyframes loaderKeyframes {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
