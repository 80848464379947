.wrap {
  .content {
    :global(.card) {
      padding: 15px;
    }
    .title {
      font-weight: 600;
      margin-bottom: 15px;
      font-size: 1.5rem;
    }
    .section_title {
      font-weight: 600;
      margin-bottom: 4px;
      font-size: 1.2rem;
    }
    .fields {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .submit_btn_wrap {
      button {
        margin-top: 2rem;
        font-size: 15px;
        border: 1px solid #008700;
        background-color: transparent;
        color: #008700;
        width: max-content;
        padding: 7px 32px;
      }
    }
    .date_wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      :global(.react-datepicker-wrapper) {
        width: 100%;
        max-width: 440px;
      }
      .error_text {
        margin-top: 4px;
      }
      input {
        width: 100%;
        border: none;
        border: 2px solid #8798aeb3;
        outline: none;
        padding: 8px;
        border-radius: 2px;
      }
      .error input {
        border-color: #ff4343;
      }
    }
    .private_checkbox {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      gap: 8px;
      cursor: pointer;
      width: max-content;
      .checkbox svg {
        color: #008700;
        font-size: 24px;
      }
    }
  }
}

.field_name {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2px;
  padding-bottom: 3px;
  display: block;
}

.error_text {
  font-size: 12px;
  color: #ff4343;
  font-weight: 500;
}
