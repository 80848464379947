.emails_section {
  width: 100%;
  max-width: 440px;
  .top_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .emails_list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    label {
      width: 100%;
      margin-bottom: 0 !important;
    }
    .email_line {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 10px;
      .btns {
        display: flex;
        flex-wrap: nowrap;
        gap: 4px;
        align-items: center;
        .icon {
          &.hide {
            visibility: hidden;
            pointer-events: none;
          }
          cursor: pointer;
          svg {
            font-size: 18px;
          }
          &:nth-of-type(1) {
            svg {
              color: #008700;
            }
          }
          &:nth-of-type(2) {
            svg {
              color: #ff4343;
            }
          }
        }
      }
    }
  }
}

.section_title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2px;
  padding-bottom: 3px;
  display: block;
}
