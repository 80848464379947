.wrap {
  padding: 0;
  position: relative;
  max-width: 440px;
  width: 100%;
}

label.wrap textarea.textarea {
  outline: none;
  border: 2px solid #8798aeb3;
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0px;
  width: 100%;
  resize: none;
  padding: 8px;
  &:disabled,
  &:read-only {
    opacity: 0.6;
  }
  &.error {
    border-bottom: 2px solid #ff4343;
  }
  &.error:focus {
    border-bottom: 2px solid #ff4343;
  }
}
.field_name {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2px;
  padding-bottom: 3px;
  display: block;
}

.error_text {
  font-size: 12px;
  color: #ff4343;
  font-weight: 500;
}
