.btn {
  border-radius: 10px;
  color: white !important;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  padding: 10px 30px;
  border: 1px solid transparent;
}

.btn:disabled {
  opacity: 0.6;
}

.btnSecondary {
  background: #2f3c4e;
  border-color: #2f3c4e;
}

.btnPrimary {
  background: #008700;
}

.btnDanger {
  background: #ff4343;
  border-color: #ff4343;
}

.btnDefault {
  background: #008700;
}
